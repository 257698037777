import React, { useState } from 'react';

import { Modal } from 'react-bootstrap';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export function RegisterPhonenumber(props) {
    const [ modal, setModal ] = useState(false);

    const handleModalClose = () => setModal(false);
    const handleModalShow = () => setModal(true);

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    return (
        <div className='d-flex flex-column-fluid flex-center'>
            <div className='login-form'>
                <form className='form fv-plugins-bootstrap fv-plugins-framework' id='kt_login_signup_form'>
                    <div className='pb-13 pt-lg-0 pt-5'>
                        <h3 className='font-weight-bolder text-dark font-size-h4 font-size-h1-lg'>สร้างบัญชี</h3>
                        <p className='text-muted font-weight-bold font-size-h4'>ลงทะเบียนด้วยเบอร์ปันสุขของคุณ</p>
                    </div>
                    <div className='form-group fv-plugins-icon-container'>
                        <label className='font-size-h6 font-weight-bolder'>เบอร์ซิมปันสุข</label>
                        <input
                            type='text'
                            inputMode='tel'
                            className='form-control h-auto py-5 px-6 rounded-lg'
                            name='phonenumber'
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    if (event.key !== 'Enter') {
                                        event.preventDefault();
                                    }
                                }
                            }}
                            value={props.phonenumber}
                            onChange={(e) => props.setPhonenumber(e.target.value)}
                            maxLength='10'
                            onInput={maxLengthCheck}
                        />
                    </div>
                    <div className='form-group fv-plugins-icon-container'>
                        <div className='radio-inline'>
                            <label className='radio radio-success mr-1'>
                                <input
                                    type='radio'
                                    name='agree-btn'
                                    checked={props.agreement}
                                    onClick={(e) => props.setAgreement(!props.agreement)}
                                />
                                <span />
                                ฉันได้อ่านและยอมรับ
                                {/* <div className='text-hover-primary' onClick={handleModalShow}>
                                    ข้อกำหนดในการให้บริการของ XXX
                                </div> */}
                            </label>
                            <div className='cursor-pointer text-hover-primary' onClick={handleModalShow}>
                                <u>ข้อกำหนดในการให้บริการของ P2Plearning</u>
                            </div>
                        </div>
                        {/* <label className='checkbox mb-0'>
                            <input type='checkbox' name='agree' />
                            <span />
                            <div className='ml-2'>
                                ฉันได้อ่านและยอมรับ{' '}
                                <div className='text-hover-primary' onClick={handleModalShow}>
                                    ข้อกำหนดในการให้บริการของ XXX
                                </div>
                            </div>
                        </label> */}
                        {/* <div className='text-hover-primary' onClick={handleModalShow}>
                            ข้อกำหนดในการให้บริการของ XXX
                        </div> */}
                        <div className='fv-plugins-message-container' />
                    </div>
                    <div className='form-group d-flex flex-wrap pb-lg-0 pb-3'>
                        <button
                            type='button'
                            className='btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mx-auto rounded-lg'
                            disabled={!props.agreement}
                            onClick={props.btnNext}
                        >
                            ถัดไป
                        </button>
                    </div>
                    <div />
                </form>
            </div>

            <Modal show={modal} onHide={handleModalClose} size='lg' centered aria-labelledby='agreement-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='agreement-modal' className='col-11 p-0'>
                        ข้อกำหนดในการให้บริการของ P2Plearning
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleModalClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row justify-content-center agreement'>
                        <div className='col-lg-10 col-12 p-0'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div>
                                        <OverlayScrollbarsComponent className='h-300px'>
                                            <div className='row-inner p-5'>
                                                <div className='text-center mb-5'>
                                                    <h3 className='font-size-h1'>
                                                        ข้อกำหนดและเงื่อนไขการใช้บริการ P2P Learning
                                                    </h3>
                                                    <p className='text-muted font-weight-bold'>
                                                        ผู้ขอใช้บริการตกลงใช้บริการ P2P Learning
                                                        และตกลงผูกพันตามข้อกำหนดและเงื่อนไข ดังต่อไปนี้
                                                    </p>
                                                </div>
                                                <ol className='pl-0 pl-md-5'>
                                                    <li className='nostyle mt-3'>
                                                        <b>1. คำนิยาม</b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1 บริษัท หมายถึง
                                                            บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.2 มูลนิธิ หมายถึง
                                                            มูลนิธิซิมปันบุญ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.3 ผู้ขอใช้บริการ
                                                            หมายถึง ผู้ทำคำขอใช้บริการนี้ หรือผู้ที่เคยสมัครใช้ P2P
                                                            Learning หรือบริการนี้แล้ว
                                                            ไม่ว่าจะเป็นการสมัครด้วยวิธีใดก็ตามที่บริษัทกำหนด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.4 เงิน หมายถึง เงินบาท
                                                            หรือสกุลเงินดิจิทัลอื่นๆ ที่บริษัทกำหนด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.5 ข้อมูลส่วนบุคคล
                                                            หมายถึง
                                                            ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
                                                            ไม่ว่าทางตรงหรือทางอ้อม เช่น ชื่อ-สกุล ที่อยู่
                                                            หมายเลขประจำตัวประชาชน หมายเลขโทรศัพท์เคลื่อนที่
                                                            วันเดือนปีเกิด อีเมล เป็นต้น ข้อมูลทางชีวภาพ เช่น ลายนิ้วมือ
                                                            ใบหน้า เป็นต้น ข้อมูลทางการเงิน ข้อมูลการทำธุรกรรม และ/หรือ
                                                            ข้อมูลใดๆ ของผู้ขอใช้บริการที่ให้ไว้แก่บริษัท
                                                            หรือที่บริษัทได้รับ หรือเข้าถึงได้จากแหล่งอื่น
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.6 P2P Learning หมายถึง
                                                            ระบบทดลองการเรียนรู้สำหรับนักเรียนหลักสูตร R.U.N. เท่านั้น
                                                            ซึ่งให้บริการทำธุรกรรมทางการเงินแบบจำลองเสมือนจริง
                                                            เพื่อทดสอบและใช้งานประกอบการเรียนการสอนสกุลเงินดิจิทัลของบริษัทและมิใช่ธุรกรรมทางการเงินด้วยตนเอง
                                                            ผ่านโทรศัพท์เคลื่อนที่ และ/หรือแท็บเล็ต
                                                            และ/หรือเครื่องมืออื่นใดตามที่บริษัทกำหนด
                                                            โดยผู้ขอใช้บริการต้องใช้บริการผ่านช่องทางที่บริษัทกำหนดเท่านั้น
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.7 รหัสประจำตัว หรือ
                                                            User Login หรือ Username หมายถึง
                                                            รหัสประจำตัวที่บริษัทกำหนดขึ้นและมอบให้แก่ผู้ขอใช้บริการ P2P
                                                            Learning
                                                            โดยกำหนดรหัสประจำตัวด้วยเลขประจำตัวประชาชนของผู้ขอใช้บริการใช้ในการลงทะเบียนเพื่อสมัครเข้าใช้งานบริการนี้
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.8 รหัสลับส่วนตัว
                                                            หมายถึง รหัสเข้าใช้งาน (Mobile PIN)
                                                            ที่ผู้ขอใช้บริการกำหนดขึ้นตามหมายเลขโทรศัพท์เคลื่อนที่เมืองปันสุข
                                                            เพื่อเป็นรหัสผ่านในการใช้บริการผ่านแอปพลิเคชัน P2P Learning
                                                            และหมายความรวมถึงรหัส OTP (One-time password)
                                                            เพื่อใช้ในการตั้ง Mobile PIN หรือการยืนยันตัวตน
                                                            เมื่อผู้ขอใช้บริการต้องการใช้บริการธุรกรรมต่างๆ
                                                            หรือเปลี่ยนอุปกรณ์ในการใช้บริการ หรือลืมรหัสลับส่วนตัว
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.9
                                                            หมายเลขโทรศัพท์เคลื่อนที่ หมายถึง
                                                            หมายเลขโทรศัพท์เคลื่อนที่ที่จดทะเบียนในประเทศไทย
                                                            ซึ่งผู้ใช้บริการให้ไว้กับบริษัทสำหรับการใช้บริการ P2P
                                                            Learning
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.10 เว็บไซต์ หมายถึง
                                                            ชื่อเว็บไซต์หรือที่อยู่เว็บไซต์ของบริษัท
                                                        </p>
                                                    </li>
                                                    <li className='nostyle mt-3'>
                                                        <b>
                                                            2. การใช้และการเก็บรักษา ข้อมูลส่วนบุคคล
                                                            รหัสประจำตัว/รหัสลับส่วนตัว
                                                        </b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.1
                                                            ผู้ขอใช้บริการจะต้องรักษารหัสประจำตัว รหัสลับส่วนตัว
                                                            และข้อมูลส่วนบุคคล
                                                            ไว้เป็นความลับและไม่เปิดเผยหรือกระทำการใดๆ
                                                            ที่ทำให้ผู้อื่นทราบ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.2
                                                            ผู้ขอใช้บริการต้องเก็บรักษาเครื่องมือ (โทรศัพท์เคลื่อนที่
                                                            และ/หรือแท็บเล็ต และ/หรือเครื่องมืออื่นใดตามที่บริษัทกำหนด)
                                                            สำหรับการทำรายการไว้เป็นอย่างดีในที่ปลอดภัย
                                                            โดยไม่ตกอยู่ภายใต้การครอบครองของบุคคลอื่น
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.3 กรณีรหัสลับส่วนตัว
                                                            หรืออุปกรณ์โทรศัพท์เคลื่อนที่ของผู้ขอใช้บริการ ชำรุด สูญหาย
                                                            หรือถูกโจรกรรม ผู้ขอใช้บริการต้องแจ้ง และ/หรือขอระงับธุรกรรม
                                                            และ/หรือ การใช้บริการอิเล็กทรอนิกส์ใดๆ และ/หรือ
                                                            ขออายัดหรือระงับการใช้ P2P Learning ทันทีให้บริษัททราบ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.4
                                                            ผู้ขอใช้บริการสามารถเปลี่ยนแปลงรหัสประจำตัวลูกค้า และ/หรือ
                                                            รหัสลับส่วนตัวและ/หรือ รหัสที่เกี่ยวข้องกับการเข้าใช้บริการ
                                                            P2P Learning เมื่อใดก็ได้
                                                            โดยดำเนินการตามวิธีการและเงื่อนไขตามที่บริษัทกำหนด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.5
                                                            ผู้ขอใช้บริการต้องใส่รหัสลับส่วนตัวให้ถูกต้องครบถ้วนทุกครั้ง
                                                            และหากใส่รหัสลับส่วนตัวไม่ถูกต้องเกินจำนวน 3 ครั้ง
                                                            ระบบจะระงับการให้บริการชั่วคราว
                                                            โดยผู้ขอใช้บริการต้องติดต่อบริษัท ทั้งนี้
                                                            ผู้ขอใช้บริการตกลงว่าการระงับการให้บริการชั่วคราวดังกล่าว
                                                            จะไม่มีผลเป็นการยกเลิกการทำธุรกรรมที่เป็นการทำคำสั่งไว้ล่วงหน้า
                                                            และ/หรือ ธุรกรรมที่ทำเสร็จสิ้นแล้วตามขั้นตอนการใช้บริการ P2P
                                                            Learning ในแต่ละประเภทแล้ว
                                                            และผู้ขอใช้บริการตกลงจะไม่เรียกร้องค่าเสียหายใดๆ
                                                            กับบริษัททั้งสิ้น
                                                        </p>
                                                    </li>
                                                    <li className='nostyle mt-3'>
                                                        <b>3. การเข้าใช้บริการ P2P Learning ของบริษัท</b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1 บริษัทเปิดให้บริการ
                                                            P2P Learning
                                                            สำหรับตัวแทนจำหน่ายของบริษัทที่เป็นนักเรียนหลักสูตร R.U.N.
                                                            เท่านั้น
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.2
                                                            ผู้ขอใช้บริการสามารถลงทะเบียนการใช้บริการ P2P Learning
                                                            ได้ที่บริษัท หรือลงทะเบียนด้วยตนเองผ่านช่องทางที่บริษัทกำหนด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.3
                                                            ผู้ขอใช้บริการยอมรับและตกลงจะปฏิบัติตามระเบียบ
                                                            และคู่มือวิธีปฏิบัติการใช้บริการที่บริษัทได้จัดให้ในลักษณะข้อความบนเว็บไซต์ของบริษัท
                                                            หรือลักษณะอื่นที่บริษัทเห็นสมควร
                                                            ซึ่งระเบียบและคู่มือวิธีปฏิบัติดังกล่าวถือเป็นส่วนหนึ่งของข้อตกลงนี้
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.4
                                                            ผู้ขอใช้บริการสามารถใช้บริการ P2P Learning ได้
                                                            เมื่อผู้ขอใช้บริการได้จัดทำข้อตกลงการใช้บริการ และ/หรือ
                                                            ดำเนินการใดๆ ตามที่บริษัทกำหนด
                                                            รวมทั้งได้รับอนุมัติจากบริษัทให้เป็นผู้ใช้บริการ P2P
                                                            Learning ด้วย
                                                            โดยผู้ขอใช้บริการต้องผูกพันและปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการที่บริษัทกำหนด
                                                            ทั้งนี้ ผู้ขอใช้บริการต้องทำรายการเปิดใช้บริการ P2P Learning
                                                            (Activate)
                                                            โดยการกำหนดรหัสลับส่วนตัวภายในระยะเวลาที่บริษัทกำหนด
                                                            มิฉะนั้น จะถือว่าผู้ขอใช้บริการไม่ประสงค์จะใช้บริการ P2P
                                                            Learning
                                                            และบริษัทจะทำการยกเลิกข้อมูลการลงทะเบียนและการอนุมัติให้เป็นผู้ใช้บริการ
                                                            P2P Learning โดยไม่ต้องแจ้งให้ผู้ขอใช้บริการทราบ
                                                            หากผู้ขอใช้บริการมีความประสงค์จะใช้บริการ P2P Learning
                                                            ต้องจัดทำคำขอใช้บริการใหม่
                                                            ตามวิธีการและขั้นตอนที่บริษัทกำหนด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.5 P2P Learning
                                                            สามารถผูกได้กับหมายเลขโทรศัพท์เคลื่อนที่หมายเลขเดียวเท่านั้นต่อบัญชี
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.6
                                                            ผู้ขอใช้บริการต้องมีโทรศัพท์เคลื่อนที่ในเครือข่ายระบบ Feels
                                                            หรือเครือข่ายที่จะเกิดขึ้นต่อไปในอนาคต
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.7
                                                            ผู้ขอใช้บริการตกลงว่าในกรณีที่ผู้ขอใช้บริการได้เปลี่ยนแปลงข้อมูลไปไม่ว่าด้วยเหตุใดก็ตาม
                                                            ให้ข้อกำหนดและเงื่อนไขนี้ยังคงมีผลใช้บังคับสำหรับบัญชีผู้ขอใช้บริการหมายเลขที่เปลี่ยนแปลงนั้นด้วยทุกประการ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.8
                                                            ผู้ขอใช้บริการสามารถดูข้อมูลการทำรายการย้อนหลังได้เมื่อมีการทำธุรกรรมผ่านบริการ
                                                            P2P Learning
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.9
                                                            ผู้ขอใช้บริการยินยอมชำระค่าธรรมเนียมการเชื่อมต่ออินเทอร์เน็ต
                                                            จากการใช้บริการ P2P Learning
                                                            นอกเหนือจากการใช้บริการโทรศัพท์เคลื่อนที่ตามปกติ (Air Time)
                                                            โดยผู้ขอใช้บริการสามารถตรวจสอบยอดค่าธรรมเนียมได้จากผู้ให้บริการเครือข่ายโทรศัพท์เคลื่อนที่
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.10
                                                            ผู้ขอใช้บริการยอมรับและตกลงว่าการใช้บริการใดๆ
                                                            ที่กระทำโดยใช้รหัสประจำตัวลูกค้าและรหัสลับส่วนตัว หรือ
                                                            วิธีการอื่นใดตามที่บริษัทกำหนด
                                                            ตามขั้นตอนและเงื่อนไขสำหรับการใช้บริการประเภทนั้นๆ
                                                            ให้ถือว่าเป็นการกระทำของผู้ขอใช้บริการเอง
                                                            และมีผลผูกพันต่อผู้ขอใช้บริการ
                                                            โดยผู้ขอใช้บริการไม่ต้องลงนามในเอกสารใดๆ
                                                        </p>
                                                    </li>
                                                    <li className='nostyle mt-3'>
                                                        <b>4. การให้บริการ P2P Learning</b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.1
                                                            ธุรกรรมที่มิใช่ทางด้านการเงิน ได้แก่ ข่าวสาร
                                                            ผลิตภัณฑ์ความรู้ ค้นหาสาขา และข้อมูลทางการเงิน
                                                            โดยผู้ขอใช้บริการสามารถเข้าดูข้อมูลได้ทุกวันตามระยะเวลาที่บริษัทกำหนด
                                                            ทั้งนี้
                                                            บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงระยะเวลาของรายการต่างๆ
                                                            ได้ตามแต่จะเห็นสมควร
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.2
                                                            ธุรกรรมเกี่ยวกับสกุลเงินดิจิทัลตามที่บริษัทกำหนด
                                                            ผู้ขอใช้บริการสามารถเข้าใช้บริการ P2P Learning ได้ตลอด 24
                                                            ชั่วโมง
                                                            โดยสามารถทำรายการได้ตามระยะเวลาที่บริษัทกำหนดภายในวงเงินและเงื่อนไขที่บริษัทกำหนด
                                                            ได้แก่
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- การโอนเงินดิจิทัล
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- โอนเงินดิจิทัลภายใน
                                                            P2P Learning
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- โอนเงินภายใน P2P
                                                            Learning บัญชีบุคคลอื่น
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- การบริจาค
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- อื่นๆ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทั้งนี้
                                                            วงเงินในการทำรายการดังกล่าวข้างต้น
                                                            อาจเปลี่ยนแปลงได้ตามที่บริษัทประกาศ
                                                            และเงื่อนไขตามที่บริษัทกำหนด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีผู้ขอใช้บริการทำรายการโอนเงิน
                                                            และ/หรือ รายการอื่นๆ
                                                            เสร็จสิ้นสมบูรณ์ตามข้อกำหนดของบริษัทแล้ว
                                                            บริษัทจะดำเนินการดังนี้
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                                            กรณีเป็นการทำรายการแบบมีผลทันที (Real Time)
                                                            บริษัทจะหักเงินจากบัญชีผู้ขอใช้บริการทันทีในจำนวนเท่ากับจำนวนเงินที่ระบุในรายการโอน
                                                            หรือรายการอื่นๆ (แล้วแต่กรณี)
                                                            รวมกับค่าธรรมเนียมหรือค่าบริการ (ถ้ามี)
                                                            ที่ต้องชำระให้แก่บริษัท
                                                            และโอนเข้าบัญชีที่รับโอนตามรายการดังกล่าว
                                                            รวมทั้งจะแสดงผลการทำรายการผ่านระบบ P2P Learning ของบริษัท
                                                            ซึ่งระบบจะบันทึกอัตโนมัติไว้เป็นหลักฐานการทำรายการดังกล่าว
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                                            กรณีเป็นการทำรายการแบบทำคำสั่งไว้ล่วงหน้า
                                                            บริษัทจะหักเงินไว้ในจำนวนเท่ากับจำนวนเงินที่ระบุในรายการโอนเงิน
                                                            หรือรายการอื่นๆ (แล้วแต่กรณี)
                                                            รวมกับค่าธรรมเนียมหรือค่าบริการ (ถ้ามี)
                                                            ที่ต้องชำระให้แก่บริษัท
                                                            และโอนเงินเข้าบัญชีที่รับโอนในวันที่ที่ระบุในรายการดังกล่าว
                                                            ทั้งนี้
                                                            บริษัทจะแจ้งผลการโอนเงินและการหักบัญชีแก่ผู้ขอใช้บริการตามวิธีการและช่องทางที่บริษัทกำหนด
                                                            ซึ่งระบบจะบันทึกอัตโนมัติไว้เป็นหลักฐานการทำรายการดังกล่าว
                                                            ภายในวันเดียวกันกับวันที่โอน
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรณีผู้ขอใช้บริการทำรายการถอน
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1)
                                                            ผู้ขอใช้บริการสามารถทำรายการถอน
                                                            เพื่อถอนเงินจากบัญชีของผู้ขอใช้บริการที่มีอยู่กับบริษัทตามที่บริษัทกำหนด
                                                            โดยทำรายการตามขั้นตอนและวิธีการที่บริษัทกำหนดผ่าน P2P
                                                            Learning
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)
                                                            ผู้ขอใช้บริการจะต้องใช้รหัสถอนเงินเพื่อประกอบการเบิกถอนเงินภายใน
                                                            15 นาทีนับจากที่ผู้ขอใช้บริการได้รับรหัสถอนเงิน
                                                            หากเกินระยะเวลาดังกล่าวหรือมีการขอรหัสถอนเงินเพิ่มเติม
                                                            ผู้ขอใช้บริการจะไม่สามารถใช้รหัสถอนเงินนี้ได้
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3)
                                                            ผู้ขอใช้บริการตกลงและยอมรับว่า หากมีการทำรายการผ่าน P2P
                                                            Learning โดยใช้รหัสถอนเงินที่ผู้ขอใช้บริการได้รับจากบริษัท
                                                            การทำรายการดังกล่าวถือว่าถูกต้องสมบูรณ์และผูกพันผู้ขอใช้บริการทุกประการ
                                                            โดยผู้ขอใช้บริการไม่ต้องลงลายมือชื่อในเอกสารใดๆ ทั้งสิ้น
                                                            และผู้ขอใช้บริการตกลงรับผิดชอบการกระทำดังกล่าวเสมือนหนึ่งว่าผู้ขอใช้บริการเป็นผู้กระทำด้วยตนเอง
                                                            และเป็นผู้ลงลายมือชื่อในการใช้บริการดังกล่าว
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4)
                                                            ผู้ขอใช้บริการตกลงชำระค่าธรรมเนียม และ/หรือ ค่าบริการใดๆ
                                                            ที่เกิดขึ้นจากการใช้บริการถอนเงินตามที่บริษัทกำหนดและบริษัทไม่ต้องคืนค่าธรรมเนียมที่เรียกเก็บจากผู้ขอใช้บริการไปแล้วในทุกกรณี
                                                            รวมทั้งตกลงปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้ของบริษัท
                                                        </p>
                                                        <p className='m-0'>การใช้บริการเกี่ยวกับ QR Code</p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ขอใช้บริการสามารถใช้
                                                            QR Code สำหรับรับเงินโอน หรือ สแกน QR Code
                                                            เพื่อดำเนินการตามข้อ 4.2 หรือเพื่อการบริจาค
                                                            ตามขั้นตอนที่บริษัทและวิธีการที่บริษัทกำหนดผ่าน P2P Learning
                                                            โดยผู้ขอใช้บริการตกลงยินยอมให้บริษัทหักเงินตามจำนวนเงินที่ผู้ขอใช้บริการระบุ
                                                            รวมทั้งค่าธรรมเนียม และ/หรือ ค่าบริการใดๆ
                                                            ที่ต้องชำระให้แก่บริษัท (ถ้ามี) จากบัญชีผู้ขอใช้บริการ
                                                            และให้ถือว่ารายการดังกล่าวมีผลผูกพันผู้ขอใช้บริการทุกประการ
                                                        </p>
                                                        <p className='m-0'>การตรวจสอบรายการบัญชี</p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ผู้ขอใช้บริการสามารถตรวจสอบรายการบัญชีของผู้ขอใช้บริการได้โดยดำเนินการตามวิธีการที่บริษัทกำหนด
                                                        </p>
                                                    </li>
                                                    <li className='nostyle mt-3'>
                                                        <b>5. ความรับผิดชอบของบริษัท</b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1
                                                            ผู้ขอใช้บริการยอมรับว่าการให้บริการและดำเนินการที่เกี่ยวข้อง
                                                            ตลอดจนช่องทางการให้บริการต่างๆ
                                                            ที่บริษัทจัดทำขึ้นเป็นระบบทดลองการเรียนรู้สำหรับนักเรียนหลักสูตร
                                                            R.U.N. เท่านั้น
                                                            ซึ่งให้บริการทำธุรกรรมทางการเงินแบบจำลองเสมือนจริง
                                                            เพื่อทดสอบและใช้งานประกอบการเรียนการสอนสกุลเงินดิจิทัลของบริษัท
                                                            ทั้งนี้
                                                            บริษัทตกลงรับผิดชอบต่อผู้ขอใช้บริการในความเสียหายสืบเนื่องจากบริการนี้
                                                            ในกรณีที่บริษัทมิได้ปฏิบัติตามข้อตกลงในการให้บริการ
                                                            (เว้นแต่การไม่ปฏิบัตินั้นเกิดจากการที่ผู้ขอใช้บริการมีเงินในบัญชีไม่พอ
                                                            และ/หรือไม่มี หรือถูกระงับการใช้วงเงินกับบริษัท
                                                            และ/หรือการโอนเงินมีผลทำให้ยอดเงินในบัญชีเกินกว่าวงเงินที่ตกลงกับบริษัท
                                                            และ/หรืออยู่ระหว่างการดำเนินทางกฎหมาย
                                                            และ/หรือบริษัทได้แจ้งให้ผู้ขอใช้บริการทราบถึงความขัดข้องของการโอนเงินก่อนหรือในขณะทำการโอนเงินอยู่แล้ว
                                                            และ/หรือผู้ขอใช้บริการปฏิบัติผิดเงื่อนไขหรือข้อตกลงกับบริษัท
                                                            และ/หรือเหตุสุดวิสัยอื่นใด)
                                                            หรือบริษัทไม่ปฏิบัติตามคำสั่งระงับการดำเนินการที่ได้รับแจ้งจากผู้ขอใช้บริการตามวิธีการและเงื่อนไขของข้อตกลง
                                                            หรือบริษัทยังมิได้ส่งมอบเครื่องมือการโอนเงิน (เช่น รหัส
                                                            หรือเครื่องมืออื่นใดที่บริษัทกำหนดสำหรับใช้เป็นเครื่องมือการโอนเงินสำหรับบริการ)
                                                            ให้แก่ผู้ขอใช้บริการ แต่มีรายการเกิดขึ้นโดยมิชอบ
                                                            หรือรายการที่เกิดขึ้นโดยมิชอบอันมิใช่เกิดจากความผิดของผู้ขอใช้บริการ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2
                                                            ผู้ขอใช้บริการยอมรับว่า กรณีมีรายการทำธุรกรรมทาง P2P
                                                            Learning
                                                            อันเกิดจากรหัสลับหรือเครื่องมืออื่นใดที่บริษัทมอบให้ผู้ขอใช้บริการเพื่อใช้เป็นเครื่องมือในการทำธุรกรรมทาง
                                                            P2P Learning สูญหาย หรือถูกโจรกรรม
                                                            ผู้ขอใช้บริการยอมรับผิดตามจำนวนเงิน
                                                            ที่เกิดก่อนบริษัทจะดำเนินการอายัด หรือระงับการให้บริการ P2P
                                                            Learning หรือ
                                                            การทำธุรกรรมที่มีข้อตกลงล่วงหน้าแล้วเสร็จตามระยะเวลาที่ได้กำหนด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.3 เนื่องจากบริการ P2P
                                                            Learning
                                                            เป็นบริการที่อำนวยความสะดวกให้กับผู้ขอใช้บริการของบริษัทในการสอบถามข้อมูลด้านการเงิน
                                                            การโอนเงิน การบริการอื่นๆ
                                                            บริษัทขอสงวนสิทธิ์ที่จะไม่ร่วมรับผิดชอบกับผู้ให้บริการเครือข่ายโทรศัพท์เคลื่อนที่ในความผิดพลาด
                                                            บกพร่อง ล่าช้า และ ความเสียหายใดๆ
                                                            อันเกิดจากกระบวนการรับ-ส่งข้อมูลดังกล่าวผ่านโทรศัพท์เคลื่อนที่ของผู้ให้บริการเครือข่ายโทรศัพท์เคลื่อนที่ให้แก่ผู้ขอใช้บริการ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.4
                                                            บริษัทจะไม่รับผิดชอบใดๆ ในความเสียหาย
                                                            ที่เกิดขึ้นกับผู้ขอใช้บริการอันเกิดจากสาเหตุต่อไปนี้
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.4.1
                                                            ผู้ขอใช้บริการมอบหรือเปิดเผยรหัสประจำตัว และ/หรือ
                                                            รหัสลับส่วนตัว (Mobile PIN/OTP) ให้ผู้อื่นทราบ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.4.2
                                                            ผู้ขอใช้บริการกระทำการทุจริตโดยตนเองหรือร่วมกับผู้อื่นเกี่ยวกับรหัสประจำตัว
                                                            และ/หรือ รหัสลับส่วนตัว (Mobile PIN/OTP)
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.4.3
                                                            ผู้ขอใช้บริการไม่รักษารหัสประจำตัว และ/หรือ รหัสลับส่วนตัว
                                                            (Mobile PIN/OTP) ไว้เป็นความลับ หรือกระทำการใดๆ
                                                            ที่อาจทำให้ผู้อื่นทราบ
                                                        </p>
                                                    </li>
                                                    <li className='nostyle mt-3'>
                                                        <b>6. การดำเนินการกรณีพบข้อผิดพลาด</b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ผู้ขอใช้บริการพบว่ามีข้อผิดพลาดหรือมีความผิดปกติใดๆ
                                                            ในการใช้บริการ P2P Learning
                                                            ผู้ขอใช้บริการจะต้องแจ้งเหตุให้บริษัททราบโดยเร็ว
                                                            โดยแจ้งข้อมูลตามที่บริษัทกำหนด เช่น
                                                            หมายเลขโทรศัพท์เคลื่อนที่ที่ลงทะเบียน วัน เวลาที่ทำรายการ
                                                            หมายเลขบัญชี จำนวนเงินที่ทำรายการ เป็นต้น
                                                            รวมทั้งข้อมูลอื่นใดที่เกี่ยวข้อง
                                                            บริษัทจะดำเนินการตรวจสอบข้อเท็จจริงและค้นหาสาเหตุของข้อผิดพลาด
                                                            รวมถึงแก้ไขข้อผิดพลาดดังกล่าวโดยเร็วตามมาตรฐานการให้บริการ
                                                            ทั้งนี้
                                                            บริษัทจะแจ้งผลการดำเนินการให้ผู้ขอใช้บริการทราบตามวิธีการและช่องทางต่างๆ
                                                            ที่ผู้ขอใช้บริการได้ให้ไว้
                                                        </p>
                                                    </li>
                                                    <li className='nostyle mt-3'>
                                                        <b>7. การระงับ และ/หรือการยกเลิกการให้บริการ</b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.1
                                                            ในกรณีที่ผู้ขอใช้บริการ P2P Learning
                                                            ต้องการยกเลิกการใช้บริการ
                                                            ผู้ขอใช้บริการต้องติดต่อบริษัทด้วยตัวเอง
                                                            โดยแจ้งเป็นลายลักษณ์อักษร หรือช่องทางอื่นๆ
                                                            ที่บริษัทจะกำหนดต่อไปในอนาคต
                                                            เพื่อให้บริษัททราบล่วงหน้าไม่น้อยกว่า 15 วัน
                                                            ก่อนการยกเลิกบริการนี้มีผลบังคับ
                                                            และรายการคงค้างที่ผู้ขอใช้บริการมีคำสั่งไว้ในบริการ P2P
                                                            Learning
                                                            เพื่อให้ทำรายการหลังวันที่ยกเลิกมีผลจะถูกยกเลิกโดยอัตโนมัติ
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2
                                                            ในกรณีที่ผู้ขอใช้บริการพบข้อผิดพลาดจากการดำเนินการ
                                                            หรือในกรณีที่มีเหตุที่ต้องระงับการดำเนินการภายใต้บริการนี้
                                                            ผู้ขอใช้บริการสามารถติดต่อได้ที่บริษัท หรือ Call Center
                                                            เมื่อบริษัทได้รับแจ้งการระงับการดำเนินการจากผู้ขอใช้บริการตามช่องทางดังกล่าว
                                                            บริษัทตกลงดำเนินการระงับการดำเนินการให้แล้วเสร็จตามระยะเวลาที่บริษัทได้แจ้งแก่ผู้ขอใช้บริการนั้น
                                                            โดยผู้ขอใช้บริการยังคงเป็นผู้รับผิดชอบในการดำเนินการ
                                                            และรายการธุรกรรมที่ได้เกิดขึ้นก่อนครบกำหนดระยะเวลาที่บริษัทจะมีการระงับการดำเนินการตามที่ได้รับแจ้งดังกล่าว
                                                            โดยผู้ขอใช้บริการจะต้องให้ข้อมูล วัน เวลา ผู้ที่เกี่ยวข้อง
                                                            จำนวนเงิน และลักษณะธุรกรรม
                                                            หรือข้อมูลอื่นใดตามที่บริษัทจะร้องขอและบริษัทจะทำการตรวจสอบ
                                                            และแก้ไขข้อผิดพลาด (หากมี) ให้เสร็จสิ้นภายใน 30 (สามสิบ)
                                                            วันนับจากวันที่ได้รับแจ้งข้อมูลที่ครบถ้วนและเป็นไปตามรายละเอียดดังกล่าว
                                                            เว้นแต่ต้องปฏิบัติให้เป็นไปตามหลักเกณฑ์ของทางราชการ และ/หรือ
                                                            บริษัทแห่งประเทศไทย
                                                            และจะแจ้งผลการสอบสวนให้ผู้ขอใช้บริการทราบภายใน 7 (เจ็ด) วัน
                                                            นับแต่วันทราบผลการสอบสวน
                                                            และบริษัทขอสงวนสิทธิ์ในการเพิกเฉยต่อคำร้องขอใดๆ
                                                            ที่ขัดต่อข้อกำหนด/หลักเกณฑ์ของทางราชการ และ/หรือ
                                                            บริษัทแห่งประเทศไทย นอกจากนั้น
                                                            ในกรณีที่มีข้อผิดพลาดที่บริษัทจะต้องชดใช้เงินแก่ผู้ขอใช้บริการ
                                                            บริษัทจะโอนเงินที่ผิดพลาดเข้าบัญชีผู้ขอใช้บริการภายใน 7
                                                            (เจ็ด) วันนับจากวันที่บริษัทตรวจพบข้อผิดพลาด
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.3
                                                            เมื่อผู้ขอใช้บริการเข้าใช้บริการ P2P Learning
                                                            และระบบตรวจพบว่า
                                                            โทรศัพท์มือถือของผู้ขอใช้บริการมีความเสี่ยงที่จะเกิดความเสียหายอันเนื่องมาจากการดัดแปลง
                                                            แก้ไขโทรศัพท์มือถือของผู้ขอใช้บริการเอง
                                                            หรือเกิดจากระบบเครือข่ายโทรศัพท์มือถือ และ/หรือเหตุอื่นใด
                                                            เช่น การ Jailbreak หรือ Root เป็นต้น
                                                            ผู้ขอใช้บริการตกลงยินยอมให้บริษัทระงับการให้บริการต่างๆ
                                                            ภายใต้บริการ P2P Learning ได้เป็นการชั่วคราวทันที
                                                            โดยบริษัทไม่ต้องแจ้งให้ผู้ขอใช้บริการทราบล่วงหน้า ทั้งนี้
                                                            เพื่อป้องกันความเสียหายที่จะเกิดขึ้นกับผู้ขอใช้บริการ
                                                            และผู้ขอใช้บริการยอมรับว่า
                                                            การดำเนินการดังกล่าวของบริษัทเป็นการกระทำเพื่อความปลอดภัยและคุ้มครองประโยชน์ของผู้ขอใช้บริการ
                                                            และบริษัทไม่ต้องรับผิดชอบในความเสียหายใดๆ ของผู้ขอใช้บริการ
                                                            (ถ้ามี)
                                                        </p>
                                                    </li>
                                                    <li className='nostyle mt-3'>
                                                        <b>8. การเปิดเผยข้อมูล</b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.1
                                                            ในกรณีที่บริษัทมีความจำเป็นต้องเปิดเผยข้อมูล หรือ
                                                            ธุรกรรมที่เกี่ยวข้องกับการใช้บริการของผู้ใช้บริการให้แก่หน่วยงานที่มีอำนาจตามกฎหมาย
                                                            ภายใต้บทบัญญัติของกฎหมาย คำสั่ง
                                                            หรือกฎระเบียบของรัฐหรือหน่วยงานที่มีอำนาจตามกฎหมายนั้น
                                                            ผู้ใช้บริการตกลงยินยอมให้บริษัทเปิดเผย หรือรายงานข้อมูล
                                                            และ/หรือจัดทำรายงานเกี่ยวกับธุรกรรมทางการเงินของผู้ใช้บริการ
                                                            มอบให้แก่เจ้าหน้าที่
                                                            หรือหน่วยงานรัฐที่ควบคุมกำกับดูแลบริษัทหรือหน่วยงานที่มีอำนาจตามกฎหมายนั้นได้ทุกประการ
                                                            โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
                                                            และให้ความยินยอมดังกล่าวมีผลอยู่ตลอดไป
                                                            ถึงแม้ว่าจะมีการยกเลิกการใช้บริการแล้วก็ตาม
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.2
                                                            บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงข้อกำหนดและเงื่อนไขการใช้บริการต่างๆ
                                                            ได้ตามแต่จะเห็นสมควร
                                                            นอกจากนี้บริษัทมีสิทธิ์ที่จะยกเลิกบริการดังกล่าวนี้ได้
                                                            ไม่ว่าทั้งหมดหรือบางส่วน โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                                            และสำหรับผู้ขอใช้บริการที่ไม่เข้าใช้งานระบบครั้งแรกเกินกว่า
                                                            30 (สามสิบ) วัน
                                                            นับจากวันที่ขั้นตอนการสมัครใช้บริการเสร็จสิ้น
                                                            บริษัทขอสงวนสิทธิ์ในการยกเลิกการสมัครใช้บริการนี้
                                                            โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.3
                                                            ผู้ขอใช้บริการยินยอมให้บริษัทเปิดเผยข้อมูลต่างๆ
                                                            เพื่อประโยชน์ในการดำเนินการ
                                                            และ/หรือให้บริการตามข้อตกลงและเงื่อนไขการใช้บริการนี้อันเป็นการสนับสนุนการให้บริการของบริษัท
                                                            เช่น
                                                            เพื่อทำการวิเคราะห์ป้องกันเหตุผิดปกติที่อาจเข้าข่ายการทุจริตหรือก่อให้เกิดความเสียหายต่อผู้ขอใช้บริการและบริษัท
                                                            เพื่อวิเคราะห์ผลิตภัณฑ์ที่เหมาะสมกับผู้ขอใช้บริการ เป็นต้น
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8.4
                                                            ผู้ขอใช้บริการรับทราบและตกลงให้บริษัทสามารถทำการบันทึกข้อมูลหรือบทสนทนาระหว่างผู้ขอใช้บริการกับบริษัทและ/หรือบันทึก
                                                            และ/หรือประมวลผลข้อมูลเกี่ยวกับผู้ขอใช้บริการและ/หรือรายการการใช้บริการ
                                                            และ/หรือการดำเนินการใดๆ
                                                            ที่เกี่ยวข้องกับการใช้บริการของผู้ขอใช้บริการเพื่อประโยชน์ในการปรับปรุงการบริการของบริษัทและ/หรือเพื่อการใดๆ
                                                            ได้ตามที่บริษัทเห็นสมควรและเพื่อเป็นหลักฐานการบริการและรายการใช้บริการ
                                                            ทั้งนี้
                                                            ผู้ขอใช้บริการตกลงให้ความยินยอมให้บันทึกการสนทนาและข้อมูลดังกล่าวเป็นพยานหลักฐานและเป็นการยืนยันตัวตนผู้ขอใช้บริการ
                                                            และบริษัทสามารถใช้บันทึกการสนทนาและข้อมูลดังกล่าวในกระบวนการพิจารณาใดๆ
                                                            หรือตามที่บริษัทเห็นสมควร
                                                        </p>
                                                    </li>
                                                    <p className='mt-3 m-0'>
                                                        9. การทำธุรกรรมใดๆ ผ่านบริการ P2P Learning
                                                        โดยเป็นการทำธุรกรรมของผู้ขอใช้บริการเอง
                                                        หรือเป็นการทำธุรกรรมของบุคคลอื่น
                                                        ซึ่งเกิดจากความบกพร่อง/ความผิดพลาดของผู้ขอใช้บริการเอง
                                                        ไม่ว่าโดยประการใดก็ตาม
                                                        ให้ถือว่าการทำธุรกรรมดังกล่าวถูกต้องสมบูรณ์
                                                        นับตั้งแต่เวลาที่ผู้ขอใช้บริการยืนยันการทำธุรกรรม
                                                        ซึ่งผู้ขอใช้บริการไม่สามารถยกเลิกหรือเปลี่ยนแปลงใดๆ ได้
                                                        ทั้งนี้ผู้ขอใช้บริการต้องตรวจสอบยอดเงินภายหลังจากทำรายการทุกครั้ง
                                                        โดยผู้ขอใช้บริการยินยอมรับผิดชอบต่อการทำธุรกรรมดังกล่าวทุกประการ
                                                    </p>
                                                    <p className='mt-3 m-0'>
                                                        10. ข้อมูลด้านการเงิน การบัญชี
                                                        หรือข้อมูลข่าวสารใดที่บริษัทจัดส่งให้ผู้ขอใช้บริการ
                                                        ผ่านทางโทรศัพท์เคลื่อนที่ภายใต้บริการนี้
                                                        ถือเป็นข้อมูลลับที่ผู้ขอใช้บริการไม่พึงเปิดเผยต่อบุคคลภายนอก
                                                    </p>
                                                    <p className='mt-3 m-0'>
                                                        11. การล่าช้า หรืองดเว้นใดๆ
                                                        ในการใช้สิทธิ์ของบริษัทตามกฎหมายหรือตามเงื่อนไขต่างๆ
                                                        ตลอดจนคู่มือระเบียบและบันทึกเสียงของบริษัทในระบบโทรศัพท์
                                                        ไม่ถือว่าบริษัทสละสิทธิ์ หรือให้ความยินยอมในการดำเนินการใดๆ
                                                        แก่ผู้ขอใช้บริการแต่ประการใด
                                                    </p>
                                                    <p className='mt-3 m-0'>
                                                        12. การที่บริษัทได้ส่งเอกสารหรือหนังสือใดๆ ไปยังที่อยู่
                                                        หรือสถานที่ติดต่อตามข้อมูลที่ผู้ขอใช้บริการได้ให้ไว้แก่บริษัทในคำขอใช้บริการนั้น
                                                        ไม่ว่าจะทำด้วยวิธีใดก็ตาม
                                                        ผู้ขอใช้บริการยินยอมให้ถือว่าบริษัทได้ส่ง
                                                        หรือแจ้งแก่ผู้ขอใช้บริการโดยชอบแล้ว
                                                        ในกรณีที่ผู้ขอใช้บริการเปลี่ยนแปลงชื่อ นามสกุล ที่อยู่
                                                        สถานที่ติดต่อ หรือหมายเลขโทรศัพท์
                                                        ผู้ขอใช้บริการจะแจ้งการเปลี่ยนแปลงนั้นเป็นหนังสือ
                                                        หรือแจ้งผ่านช่องทางที่บริษัทกำหนดให้บริษัททราบทันที
                                                        หากผู้ขอใช้บริการไม่แจ้งให้ถือว่าข้อมูลที่ได้แจ้งไว้เป็นข้อมูลที่ถูกต้อง
                                                    </p>
                                                    <p className='mt-3 m-0'>
                                                        13. บริษัทขอสงวนสิทธิ์ในการนำ P2P Learning ไปใช้ในเชิงพาณิชย์
                                                    </p>
                                                    <p className='mt-3 m-0'>
                                                        14. กฎหมายที่ใช้บังคับ ข้อกำหนดและเงื่อนไขการใช้บริการนี้
                                                        ให้ใช้บังคับและตีความตามกฎหมายไทย
                                                        และให้ศาลที่มีเขตอำนาจในการพิจารณาข้อพิพาทที่เกิดขึ้นเกี่ยวกับการใช้บริการตามข้อกำหนด
                                                        และเงื่อนไขการใช้บริการนี้
                                                    </p>
                                                    <p className='mt-3 m-0'>
                                                        15.
                                                        ในกรณีที่บริษัทยินยอมให้ผู้ใช้บริการสามารถสมัครใช้บริการใดของบริษัท
                                                        หรือธุรกรรมใดผ่าน P2P Learning นี้
                                                        หรือด้วยช่องทางอื่นใดตามวิธีการที่บริษัทกำหนดแทนการลงลายมือชื่อในคำขอใช้บริการ
                                                        และ/หรือ การทำธุรกรรมดังกล่าวข้างต้น
                                                        หากผู้ขอใช้บริการใช้วิธีการดังกล่าวเพื่อสมัครใช้บริการหรือทำธุรกรรมที่กล่าวนั้น
                                                        ผู้ขอใช้บริการตกลงผูกพันตามข้อกำหนดและเงื่อนไขเกี่ยวกับการใช้บริการดังกล่าวทุกประการ
                                                        เสมือนว่าผู้ขอใช้บริการได้สมัครใช้บริการหรือทำธุรกรรมโดยการลงลายมือชื่อในคำขอใช้บริการ
                                                        หรือการทำธุรกรรมดังกล่าว
                                                    </p>
                                                    <p className='mt-3 m-0'>
                                                        16.
                                                        ในกรณีที่ผู้ขอใช้บริการได้เคยตกลงผูกพันตามข้อกำหนดและเงื่อนไขการใช้บริการ
                                                        P2P Learning มาก่อนแล้ว
                                                        ไม่ว่าข้อตกลงนั้นจะอยู่ด้านหลังคำขอใช้บริการ
                                                        หรือเป็นข้อกำหนดที่เป็นแผ่นพับ
                                                        หรือได้เคยตกลงผูกพันดังกล่าวด้วยการยอมรับในเว็บไซต์ของบริษัทหรือในที่ใดก็ตาม
                                                        ผู้ขอใช้บริการตกลงปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการ P2P
                                                        Learning นี้แทนข้อตกลงหรือข้อกำหนดดังกล่าวข้างต้น
                                                    </p>
                                                    <li className='nostyle mt-3'>
                                                        <b>17. ความยินยอมในการเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล</b>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17.1
                                                            ผู้ใช้บริการตกลงยินยอมให้บริษัทเก็บ รวบรวม ใช้
                                                            เปิดเผยข้อมูลส่วนบุคคลของผู้ขอใช้บริการที่ให้ไว้หรือมีอยู่กับบริษัท
                                                            หรือที่บริษัทได้รับหรือเข้าถึงได้จากแหล่งอื่นตามที่หน่วยงานตามกฎหมายกำหนดหรือกลุ่มธุรกิจบริษัทและพันธมิตรทางธุรกิจที่มีสัญญา/ข้อตกลงที่มีอยู่กับบริษัท
                                                            โดยมีวัตถุประสงค์เพื่อการบริหารจัดการธุรกิจของบริษัท
                                                            การพิจารณาเสนอผลิตภัณฑ์ บริการ
                                                            การตรวจสอบธุรกรรมและข้อเสนอพิเศษต่างๆ ให้แก่ผู้ใช้บริการ
                                                            การปฏิบัติตามกฎหมาย
                                                            รวมถึงการที่บริษัทจ้างหรือมอบหมายบุคคลอื่นดำเนินการแทน
                                                            ไม่ว่าบางส่วนหรือทั้งหมด
                                                            โดยผู้ใช้บริการยินยอมให้บริษัทส่งหรือโอนข้อมูลส่วนบุคคลดังกล่าวได้
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17.2
                                                            หากบริษัทประสงค์ที่จะขอเก็บรวบรวม ใช้
                                                            และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการเพิ่มเติม
                                                            ผู้ใช้บริการตกลงจะให้ข้อมูลกับบริษัทตามที่บริษัทร้องขอและการให้ข้อมูลที่เพิ่มเติมในภายหลังนี้อยู่ภายใต้ข้อกำหนดและเงื่อนไขการใช้บริการฯ
                                                            ฉบับนี้
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17.3
                                                            ผู้ขอใช้บริการยอมรับว่าบริษัทไม่ต้องรับผิดชอบในความเสียหายใดๆ
                                                            ในการเก็บ รวบรวม ใช้
                                                            เปิดเผยหรือให้ข้อมูลส่วนบุคคลที่มีอยู่กับบริษัท
                                                            จนกว่าจะมีการแจ้งเพิกถอนการใช้บริการดังกล่าวต่อบริษัท
                                                            และที่เกิดขึ้นจากความผิดพลาด ล่าช้า
                                                            ชำรุดบกพร่องของระบบคอมพิวเตอร์ และ/หรือ
                                                            จากการกระทำของผู้ให้บริการโทรศัพท์เคลื่อนที่ และ/หรือ
                                                            บุคคลอื่นใดดังกล่าวแต่ประการใดทั้งสิ้น
                                                        </p>
                                                        <p className='m-0'>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ข้าพเจ้าได้อ่านและเข้าใจข้อกำหนดและเงื่อนไขการใช้บริการ
                                                            P2P Learning เรียบร้อยแล้ว
                                                            และตกลงยินยอมผูกพันและรับปฏิบัติตามข้อกำหนดและเงื่อนไขดังกล่าว
                                                            รวมทั้งตกลงยินยอมเสียค่าธรรมเนียมและ/หรือค่าใช้จ่ายใดๆ
                                                            อันเนื่องมาจากการที่ข้าพเจ้าใช้บริการ
                                                            ตามที่บริษัทกำหนดทุกประการ
                                                            แต่หากข้าพเจ้าไม่สามารถดำเนินการหรือปฏิบัติตามข้อกำหนดและเงื่อนไขดังกล่าว
                                                            ไม่ว่าบางส่วนหรือทั้งหมด จนเป็นเหตุให้เกิดความเสียหายใดๆ
                                                            ข้าพเจ้ายินยอมรับผิดชอบทั้งสิ้น
                                                            พร้อมยกเลิกและยุติการใช้บริการ P2P Learning
                                                            โดยจะแจ้งให้บริษัททราบทันที
                                                        </p>
                                                    </li>
                                                </ol>
                                            </div>
                                        </OverlayScrollbarsComponent>

                                        {/* <div className='row mt-7 px-5'>
                                            <div className='text-center'>
                                                <div className='radio-inline'>
                                                    <label className='radio radio-success'>
                                                        <input
                                                            type='radio'
                                                            name='agree-btn'
                                                            checked={props.agreement}
                                                            onClick={() => props.setAgreement(!props.agreement)}
                                                        />
                                                        <span />
                                                        ยอมรับข้อกำหนดและเงื่อนไข
                                                    </label>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <div className='form-group d-flex flex-wrap flex-center mb-0'>
                                            <button
                                                type='button'
                                                className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                                                onClick={props.btnNext}
                                                disabled={!props.agreement}
                                            >
                                                <span>ถัดไป</span>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <div className='row justify-content-end m-0'>
                        <div className='col-12 p-0'>
                            <button type='button' className='btn btn-primary rounded-lg' onClick={handleDownload}>
                                บันทึกคิวอาร์โค้ด
                            </button>
                        </div>
                    </div> */}
                </Modal.Footer>
            </Modal>
        </div>
    );
}
