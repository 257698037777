import React, {useMemo} from "react";
import { useSelector } from 'react-redux';
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import {HeaderMenu} from "./HeaderMenu";

export function HeaderMenuWrapper() {
    const { user } = useSelector((state) => state.auth);
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(
                uiService.config,
                "header.menu.self.root-arrow"
            ),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false
        };
    }, [uiService]);

    return <>
        {/*begin::Header Menu Wrapper*/}
        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            <div
                className={
                    'd-flex align-items-center p-5 w-auto'
                }
            >
                <span className='text-dark opacity-70 font-weight-bold font-size-base d-md-none d-flex d-md-inline mr-1'>
                    สวัสดี
                </span>
                <span className='text-dark opacity-90 font-weight-bolder font-size-base d-md-none d-flex d-md-inline mr-4'>
                    {user.firstname}
                    {/* Test */}
                </span>
                {/* <span className='symbol symbol-35'>
                    <span className='symbol-label text-dark font-size-h5 font-weight-bold d-md-none d-flex bg-white-o-30'>
                        {user.firstname[0]}
                    </span>
                </span> */}
            </div>
            <div className='separator separator-solid' />
            {/*begin::Header Menu*/}
            <HeaderMenu layoutProps={layoutProps} />
            {/*end::Header Menu*/}
        </div>
        {/*Header Menu Wrapper*/}
    </>
}
