import React, { useState } from 'react';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

// import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { RegisterOtpcode, RegisterPhonenumber } from './components';

import * as auth from '../_redux/authRedux';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

function Register(props) {
    // let history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    // phonenumber page
    const [ phonenumber, setPhonenumber ] = useState('');
    const [ agreement, setAgreement ] = useState(false);
    // otpcode page
    const [ refcode, setRefcode ] = useState('');
    const [ otpcode, setOtpcode ] = useState('');
    const [ expiretime, setExpiretime ] = useState('');
    const [ disablebtn, setDisableBtn ] = useState(true);
    const [ disableotp, setDisableOtp ] = useState(false);

    const btnNext = () => {
        if (page === 'main') {
            if (phonenumber === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `กรุณากรอกเบอร์ซิมปันสุขให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
                return;
            }
            getOtpcode();
        } else if (page === 'otpcode') {
            Register();
        }
    };

    const handleOtp = (code) => {
        setOtpcode(code);
        if (code.length >= 6) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    };

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={(e) => getOtpcode(true)} disabled={disableotp}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        // console.log(minutes);
        // console.log(seconds);
        // console.log(completed);
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    const getOtpcode = (retry = false) => {
        setOtpcode('');
        setLoading(true);
        setDisableOtp(true);
        fetch(config.crypto_url + 'punsook/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setDisableOtp(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    setExpiretime(result.timeout);
                    setDisableBtn(true);
                    if (!retry) {
                        setPage('otpcode');
                    }
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        heightAuto: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setDisableOtp(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
            });
    };

    const Register = () => {
        setLoading(true);
        fetch(config.crypto_url + 'user/register', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber,
                otp: otpcode,
                ref: refcode
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    props.newLogin(result.info);
                    // history.push('/dashboard');
                } else if (result.responsecode === 100) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `หมายเลข ${phonenumber} ไม่ใช่เบอร์ในโครงการซิมปันสุข ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขได้`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        heightAuto: false
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        heightAuto: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setDisableOtp(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
            });
    };
    return (
        <>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto w-500px'>
                    {page === 'main' ? (
                        <RegisterPhonenumber
                            agreement={agreement}
                            setAgreement={setAgreement}
                            phonenumber={phonenumber}
                            setPhonenumber={setPhonenumber}
                            btnNext={btnNext}
                        />
                    ) : page === 'otpcode' ? (
                        <RegisterOtpcode
                            Completionist={Completionist}
                            renderer={renderer}
                            otpcode={otpcode}
                            refcode={refcode}
                            expiretime={expiretime}
                            handleOtp={handleOtp}
                            disablebtn={disablebtn}
                            btnNext={btnNext}
                        />
                    ) : null}
                </div>
            )}
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(Register));
