import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_metronic/layout';
import * as auth from '../_redux/authRedux';

class Logout extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // console.log(this.props.location.search);
        this.props.logout();
    }

    render() {
        const { hasAuthToken } = this.props;
        return hasAuthToken ? <LayoutSplashScreen /> : this.props.location.search !== '?expire' ? <Redirect to='/auth/login' /> : <Redirect to='/auth/login/expire' />;
    }
}

export default connect(({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }), auth.actions)(Logout);
