import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
// import { BuilderPage } from './pages/BuilderPage';
// import { MyPage } from './pages/MyPage';
// import {DashboardPage} from "./pages/DashboardPage";
import { Homepage } from './pages/Homepage';

// const GoogleMaterialPage = lazy(() => import('./modules/GoogleMaterialExamples/GoogleMaterialPage'));
// const ReactBootstrapPage = lazy(() => import('./modules/ReactBootstrapExamples/ReactBootstrapPage'));
// const ECommercePage = lazy(() => import('./modules/ECommerce/pages/eCommercePage'));

export default function BasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from='/' to='/luckydraw' />
                }
                <ContentRoute path='/luckydraw' component={Homepage} />
                {/* <ContentRoute path='/builder' component={BuilderPage} />
                <ContentRoute path='/my-page' component={MyPage} />
                <Route path='/google-material' component={GoogleMaterialPage} />
                <Route path='/react-bootstrap' component={ReactBootstrapPage} />
                <Route path='/e-commerce' component={ECommercePage} /> */}
                <Redirect to='error/error-v1' />
            </Switch>
        </Suspense>
    );
}
