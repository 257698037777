import React, { useState } from 'react';

import CountUp from 'react-countup';

import { toAbsoluteUrl } from '../../_metronic/_helpers';

const numberList = [
    '0976947899',
    '0974549687',
    '0976914252',
    '0976535105',
    '0976913858',
    '0976982450',
    '0976985556',
    '0976928001',
    '0976940659',
    '0976941188'
];

const randomList = [];

export function Homepage() {
    const [ loading, setLoading ] = useState(false);
    const [ disable, setDisable ] = useState(false);
    const [ numerber, setNumber ] = useState('0000000000');
    const [ round, setRound ] = useState(0);

    const random = () => {
        setLoading(true);
        setDisable(true);
        setNumber('0000000000');
        setTimeout(() => {
            setLoading(false);
            setNumber(numberList[round % numberList.length]);
            setRound(round + 1);
            setDisable(false);
        }, 2000);
    };

    return (
        <div className='row m-0'>
            <div
                className='col-12 p-0 vh-100'
                style={{
                    backgroundImage: `url(${toAbsoluteUrl('/media/bg/lucky-draw-221014.jpg')})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
            >
                <div className='d-flex justify-content-center align-items-center h-100 row m-0'>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(0)
                        )}
                        {/* {SimpleHook} */}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(1)
                        )}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(2)
                        )}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(3)
                        )}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(4)
                        )}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(5)
                        )}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(6)
                        )}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(7)
                        )}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(8)
                        )}
                    </div>
                    <div className='font-size-5rem col-1 text-center'>
                        {loading ? (
                            <CountUp
                                end={9}
                                duration={0.2}
                                onEnd={({ reset, start }) => {
                                    reset();
                                    start();
                                }}
                            />
                        ) : (
                            numerber.charAt(9)
                        )}
                    </div>
                </div>
                <button
                    type='button'
                    className='btn btn-primary rounded-lg px-16 py-3 font-size-h1'
                    onClick={random}
                    style={{
                        position: 'absolute',
                        bottom: '15rem',
                        left: '43%'
                    }}
                    disabled={disable}
                >
                    สุ่ม
                </button>
            </div>
            {/* <div className='col-md-3 col-12 p-0'>
                <div className='d-flex justify-content-center align-items-center h-100 row m-0'>
                    <div className='col-12'>
                        <div className='card rounded-lg'>
                            <div className='card-header rounded-lg rounded-bottom-0'>
                                <h3 className='card-title m-0'>รายชื่อผู้ได้รับรางวัล</h3>
                            </div>
                            <div className='card-body'>
                                <OverlayScrollbarsComponent className='max-h-400px px-5'>
                                    {numberlistloading ? (
                                        <div className='row'>
                                            <i className='fas fa-spinner fa-pulse fa-3x my-auto mx-auto' />
                                        </div>
                                    ) : (
                                        <table className='table'>
                                            <tbody className='past-drawn-item'>
                                                {numberlist.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {value.number} ({value.event === 'N' ? 'ทางบ้าน' : 'ในงาน'})
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    )}
                                </OverlayScrollbarsComponent>
                            </div>
                        </div>
                    </div>
                    <button type='button' className='btn btn-primary rounded-lg px-16 py-3' onClick={() => random('N')}>
                        สุ่ม (ทางบ้าน)
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary rounded-lg px-16 py-3 ml-3'
                        onClick={() => random('Y')}
                    >
                        สุ่ม (ในงาน)
                    </button>
                </div>
            </div> */}
        </div>
    );
}
