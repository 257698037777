import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';

const initialValues = {
    username: '',
    password: ''
};

const masterValues = {
    username: 'admin',
    password: '12345678'
};

function Login2(props) {
    const [ loading, setLoading ] = useState(false);
    const [ passwordShown, setPasswordShown ] = useState(false);
    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('กรุณากรอกชื่อผู้ใช้งาน'),
        password: Yup.string().required('กรุณากรอกรหัสผ่าน')
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }

        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            if (values.username === masterValues.username) {
                if (values.password === masterValues.password) {
                    disableLoading();
                    props.newLogin({ login: true });
                } else {
                    disableLoading();
                    setSubmitting(false);
                    setStatus('ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง');
                }
            } else {
                disableLoading();
                setSubmitting(false);
                setStatus('ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง');
            }
        }
    });

    return (
        <div className='login-form login-signin' id='kt_login_signin_form'>
            <div className='form-group d-flex flex-wrap justify-content-center align-items-center'>
                <a href={`https://play-api.feels.co.th/facebook/login`} target='_blank' rel='noopener noreferrer'>
                    {/* <button type='button' className={`btn btn-primary font-weight-bold px-9 py-4 my-3 rounded-lg`}>
                        <span>เข้าสู่ระบบ</span>
                    </button> */}
                    <div className='auth-provider facebook-login row'>
                        <svg
                            aria-hidden='true'
                            className='svg-icon my-auto mr-3'
                            width={18}
                            height={18}
                            viewBox='0 0 18 18'
                        >
                            <path
                                d='M1.88 1C1.4 1 1 1.4 1 1.88v14.24c0 .48.4.88.88.88h7.67v-6.2H7.46V8.4h2.09V6.61c0-2.07 1.26-3.2 3.1-3.2.88 0 1.64.07 1.87.1v2.16h-1.29c-1 0-1.19.48-1.19 1.18V8.4h2.39l-.31 2.42h-2.08V17h4.08c.48 0 .88-.4.88-.88V1.88c0-.48-.4-.88-.88-.88H1.88z'
                                fill='#fff'
                            />
                        </svg>
                        เข้าสู่ระบบด้วย Facebook
                    </div>
                </a>
            </div>
            {/* <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>ลงชื่อเข้าใช้</h3>
            </div>
            <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
                {formik.status && (
                    <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible rounded-lg'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}
                <div className='form-group fv-plugins-icon-container'>
                    <label className='font-size-h6 font-weight-bolder'>ชื่อผู้ใช้งาน</label>
                    <input
                        type='text'
                        className={`form-control form-control-solid h-auto py-5 px-6 rounded-lg ${getInputClasses(
                            'username'
                        )}`}
                        name='username'
                        {...formik.getFieldProps('username')}
                    />
                    {formik.touched.username && formik.errors.username ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>{formik.errors.username}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group fv-plugins-icon-container'>
                    <label className='font-size-h6 font-weight-bolder'>
                        รหัสผ่าน
                        <i
                            className={`fa ${!passwordShown ? 'fa-eye' : 'fa-eye-slash'} cursor-pointer ml-3`}
                            onClick={() => setPasswordShown(!passwordShown)}
                            aria-hidden='true'
                        />
                    </label>
                    <input
                        type={!passwordShown ? 'password' : 'text'}
                        inputMode='tel'
                        className={`form-control form-control-solid h-auto py-5 px-6 rounded-lg ${getInputClasses(
                            'password'
                        )}`}
                        name='password'
                        {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group d-flex flex-wrap justify-content-center align-items-center'>
                    <button
                        id='kt_login_signin_submit'
                        type='submit'
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3 rounded-lg`}
                    >
                        <span>เข้าสู่ระบบ</span>
                        {loading && <span className='ml-3 spinner spinner-white' />}
                    </button>
                </div>
            </form> */}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login2));
